import { default as _91slug_93kGRpYjWbdaMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/articles/[slug].vue?macro=true";
import { default as indexzdz78rCpO0Meta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/articles/index.vue?macro=true";
import { default as _91slug_93TcsrpHRK7UMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/beliefs/[slug].vue?macro=true";
import { default as indexOEU4WmL8PAMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/beliefs/index.vue?macro=true";
import { default as campaignskgobRA8zCLMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/campaigns.vue?macro=true";
import { default as contact_45usoCke1cSwQGMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/contact-us.vue?macro=true";
import { default as discoveryjdmh8JImcMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/discover.vue?macro=true";
import { default as _91slug_93MAnEgUmjxaMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/elevenpoints/[slug].vue?macro=true";
import { default as indexnjyKhJ93dNMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/elevenpoints/index.vue?macro=true";
import { default as exhibitionsLjKIFjR1MwMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/exhibitions.vue?macro=true";
import { default as indexgPZcLUVicNMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/index.vue?macro=true";
import { default as index7PGOF4PVSFMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/index.vue?macro=true";
import { default as introductionYoz8quoHlVMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/introduction.vue?macro=true";
import { default as the_45campaigndajN22fTjiMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/the-campaign.vue?macro=true";
import { default as the_45critical_45need_45for_45peacetHY0awEsK6Meta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/the-critical-need-for-peace.vue?macro=true";
import { default as the_45prophecyiyBRMUMGrqMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/the-prophecy.vue?macro=true";
import { default as submit_45your_45questionfU99Wivz3jMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/submit-your-question.vue?macro=true";
import { default as terms_45of_45servicex6Y0r1XqY2Meta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/terms-of-service.vue?macro=true";
import { default as the_45messiah_45has_45cometC3VkctBLpMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/the-messiah-has-come.vue?macro=true";
import { default as topics_45indexEilK8dcuqhMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/topics-index.vue?macro=true";
import { default as use_45of_45cookies3pYfLnWcixMeta } from "/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/use-of-cookies.vue?macro=true";
export default [
  {
    name: _91slug_93kGRpYjWbdaMeta?.name ?? "articles-slug",
    path: _91slug_93kGRpYjWbdaMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93kGRpYjWbdaMeta || {},
    alias: _91slug_93kGRpYjWbdaMeta?.alias || [],
    redirect: _91slug_93kGRpYjWbdaMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexzdz78rCpO0Meta?.name ?? "articles",
    path: indexzdz78rCpO0Meta?.path ?? "/articles",
    meta: indexzdz78rCpO0Meta || {},
    alias: indexzdz78rCpO0Meta?.alias || [],
    redirect: indexzdz78rCpO0Meta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TcsrpHRK7UMeta?.name ?? "beliefs-slug",
    path: _91slug_93TcsrpHRK7UMeta?.path ?? "/beliefs/:slug()",
    meta: _91slug_93TcsrpHRK7UMeta || {},
    alias: _91slug_93TcsrpHRK7UMeta?.alias || [],
    redirect: _91slug_93TcsrpHRK7UMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/beliefs/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexOEU4WmL8PAMeta?.name ?? "beliefs",
    path: indexOEU4WmL8PAMeta?.path ?? "/beliefs",
    meta: indexOEU4WmL8PAMeta || {},
    alias: indexOEU4WmL8PAMeta?.alias || [],
    redirect: indexOEU4WmL8PAMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/beliefs/index.vue").then(m => m.default || m)
  },
  {
    name: campaignskgobRA8zCLMeta?.name ?? "campaigns",
    path: campaignskgobRA8zCLMeta?.path ?? "/campaigns",
    meta: campaignskgobRA8zCLMeta || {},
    alias: campaignskgobRA8zCLMeta?.alias || [],
    redirect: campaignskgobRA8zCLMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/campaigns.vue").then(m => m.default || m)
  },
  {
    name: contact_45usoCke1cSwQGMeta?.name ?? "contact-us",
    path: contact_45usoCke1cSwQGMeta?.path ?? "/contact-us",
    meta: contact_45usoCke1cSwQGMeta || {},
    alias: contact_45usoCke1cSwQGMeta?.alias || [],
    redirect: contact_45usoCke1cSwQGMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: discoveryjdmh8JImcMeta?.name ?? "discover",
    path: discoveryjdmh8JImcMeta?.path ?? "/discover",
    meta: discoveryjdmh8JImcMeta || {},
    alias: discoveryjdmh8JImcMeta?.alias || [],
    redirect: discoveryjdmh8JImcMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93MAnEgUmjxaMeta?.name ?? "elevenpoints-slug",
    path: _91slug_93MAnEgUmjxaMeta?.path ?? "/elevenpoints/:slug()",
    meta: _91slug_93MAnEgUmjxaMeta || {},
    alias: _91slug_93MAnEgUmjxaMeta?.alias || [],
    redirect: _91slug_93MAnEgUmjxaMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/elevenpoints/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexnjyKhJ93dNMeta?.name ?? "elevenpoints",
    path: indexnjyKhJ93dNMeta?.path ?? "/elevenpoints",
    meta: indexnjyKhJ93dNMeta || {},
    alias: indexnjyKhJ93dNMeta?.alias || [],
    redirect: indexnjyKhJ93dNMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/elevenpoints/index.vue").then(m => m.default || m)
  },
  {
    name: exhibitionsLjKIFjR1MwMeta?.name ?? "exhibitions",
    path: exhibitionsLjKIFjR1MwMeta?.path ?? "/exhibitions",
    meta: exhibitionsLjKIFjR1MwMeta || {},
    alias: exhibitionsLjKIFjR1MwMeta?.alias || [],
    redirect: exhibitionsLjKIFjR1MwMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/exhibitions.vue").then(m => m.default || m)
  },
  {
    name: indexgPZcLUVicNMeta?.name ?? "index",
    path: indexgPZcLUVicNMeta?.path ?? "/",
    meta: indexgPZcLUVicNMeta || {},
    alias: indexgPZcLUVicNMeta?.alias || [],
    redirect: indexgPZcLUVicNMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7PGOF4PVSFMeta?.name ?? "stopww3",
    path: index7PGOF4PVSFMeta?.path ?? "/stopww3",
    meta: index7PGOF4PVSFMeta || {},
    alias: index7PGOF4PVSFMeta?.alias || [],
    redirect: index7PGOF4PVSFMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/index.vue").then(m => m.default || m)
  },
  {
    name: introductionYoz8quoHlVMeta?.name ?? "stopww3-introduction",
    path: introductionYoz8quoHlVMeta?.path ?? "/stopww3/introduction",
    meta: introductionYoz8quoHlVMeta || {},
    alias: introductionYoz8quoHlVMeta?.alias || [],
    redirect: introductionYoz8quoHlVMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/introduction.vue").then(m => m.default || m)
  },
  {
    name: the_45campaigndajN22fTjiMeta?.name ?? "stopww3-the-campaign",
    path: the_45campaigndajN22fTjiMeta?.path ?? "/stopww3/the-campaign",
    meta: the_45campaigndajN22fTjiMeta || {},
    alias: the_45campaigndajN22fTjiMeta?.alias || [],
    redirect: the_45campaigndajN22fTjiMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/the-campaign.vue").then(m => m.default || m)
  },
  {
    name: the_45critical_45need_45for_45peacetHY0awEsK6Meta?.name ?? "stopww3-the-critical-need-for-peace",
    path: the_45critical_45need_45for_45peacetHY0awEsK6Meta?.path ?? "/stopww3/the-critical-need-for-peace",
    meta: the_45critical_45need_45for_45peacetHY0awEsK6Meta || {},
    alias: the_45critical_45need_45for_45peacetHY0awEsK6Meta?.alias || [],
    redirect: the_45critical_45need_45for_45peacetHY0awEsK6Meta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/the-critical-need-for-peace.vue").then(m => m.default || m)
  },
  {
    name: the_45prophecyiyBRMUMGrqMeta?.name ?? "stopww3-the-prophecy",
    path: the_45prophecyiyBRMUMGrqMeta?.path ?? "/stopww3/the-prophecy",
    meta: the_45prophecyiyBRMUMGrqMeta || {},
    alias: the_45prophecyiyBRMUMGrqMeta?.alias || [],
    redirect: the_45prophecyiyBRMUMGrqMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/stopww3/the-prophecy.vue").then(m => m.default || m)
  },
  {
    name: submit_45your_45questionfU99Wivz3jMeta?.name ?? "submit-your-question",
    path: submit_45your_45questionfU99Wivz3jMeta?.path ?? "/submit-your-question",
    meta: submit_45your_45questionfU99Wivz3jMeta || {},
    alias: submit_45your_45questionfU99Wivz3jMeta?.alias || [],
    redirect: submit_45your_45questionfU99Wivz3jMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/submit-your-question.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45servicex6Y0r1XqY2Meta?.name ?? "terms-of-service",
    path: terms_45of_45servicex6Y0r1XqY2Meta?.path ?? "/terms-of-service",
    meta: terms_45of_45servicex6Y0r1XqY2Meta || {},
    alias: terms_45of_45servicex6Y0r1XqY2Meta?.alias || [],
    redirect: terms_45of_45servicex6Y0r1XqY2Meta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/terms-of-service.vue").then(m => m.default || m)
  },
  {
    name: the_45messiah_45has_45cometC3VkctBLpMeta?.name ?? "the-messiah-has-come",
    path: the_45messiah_45has_45cometC3VkctBLpMeta?.path ?? "/the-messiah-has-come",
    meta: the_45messiah_45has_45cometC3VkctBLpMeta || {},
    alias: the_45messiah_45has_45cometC3VkctBLpMeta?.alias || [],
    redirect: the_45messiah_45has_45cometC3VkctBLpMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/the-messiah-has-come.vue").then(m => m.default || m)
  },
  {
    name: topics_45indexEilK8dcuqhMeta?.name ?? "topics-index",
    path: topics_45indexEilK8dcuqhMeta?.path ?? "/topics-index",
    meta: topics_45indexEilK8dcuqhMeta || {},
    alias: topics_45indexEilK8dcuqhMeta?.alias || [],
    redirect: topics_45indexEilK8dcuqhMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/topics-index.vue").then(m => m.default || m)
  },
  {
    name: use_45of_45cookies3pYfLnWcixMeta?.name ?? "use-of-cookies",
    path: use_45of_45cookies3pYfLnWcixMeta?.path ?? "/use-of-cookies",
    meta: use_45of_45cookies3pYfLnWcixMeta || {},
    alias: use_45of_45cookies3pYfLnWcixMeta?.alias || [],
    redirect: use_45of_45cookies3pYfLnWcixMeta?.redirect,
    component: () => import("/Users/cruiiz/Git/trueislamV5/apps/front/src/pages/use-of-cookies.vue").then(m => m.default || m)
  }
]